<template>
    <div class="footer" role="navigation">
        <div class="row" v-if="!exporterPage">
            <div class="col-md-12">
                <div class="footer-version">
                    <router-link to="/release-notes" class="release-notes" rel="noopener">
                        <span class="version">Initial public release of Modernized NIH RePORTER, version 2020.9</span>,
                        Release Notes
                    </router-link>
                    <div class="data-last-update">Data as of {{ lastRefreshed }}</div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <ul>
                    <li><a v-bind:href="reportUrl + '/about'" rel="noopener">About RePORT</a></li>
                    <li><a v-bind:href="reportUrl + '/faqs'" rel="noopener">FAQs</a></li>
                    <li><a v-bind:href="reportUrl + '/contactus'" rel="noopener">Contact Us</a></li>
                    <li><a v-bind:href="reportUrl + '/sitemap'" rel="noopener">Site Map</a></li>
                    <li><a v-bind:href="reportUrl + '/' + dataAccessPolicyURL" rel="noopener">Data Access
                            Policy</a></li>
                    <li><a v-bind:href="reportUrl + '/accessibility-statement'" rel="noopener">Accessibility Statement</a></li>
                    <li><a :href="privacyURL" rel="noopener">Privacy Statement</a></li>
                    <li><a :href="diclaimerURL" rel="noopener">Disclaimer</a></li>
                    <li><a :href="foiaURL" rel="noopener">FOIA</a></li>
                    <li><a :href="vulnerabilityDisclosure" rel="noopener">HHS Vulnerability
                            Disclosure</a></li>
                </ul>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 footer-tagline text-start">
                As the data are available for bulk download, the RePORTER system reserves the right to block IP
                addresses that fail to adhere to instructions in the system's robots.txt files or submit requests at a
                rate that negatively impacts service delivery to other users. RePORTER reserves the right to terminate
                any automated query to the RePORTER application that negatively affects service delivery to other users.
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 footer-tagline">
                <ul>
                    <li><a :href="oerURL" rel="noopener">Office of Extramural Research</a></li>
                    <li><a :href="nihURL" rel="noopener">National Institutes of Health</a></li>
                    <li><a :href="hhsURL" rel="noopener">U.S. Department of Health and Human Services</a></li>
                    <li><a :href="usaGovURL" rel="noopener">USA.Gov - Government Made Easy</a></li>
                    <li><a :href="grantsURL" rel="noopener">Grants.Gov</a></li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <p class="footer-tagline">NIH...<em>Turning Discovery Into Health<sup>&reg;</sup></em></p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
 import axios, * as others from 'axios';

export default {
    name: 'FooterNav',
    // data: function () {
    //     return {
    //         isFullscreen: false,
    //         maxWidth: 0
    //     };
    // },
    data: function () {
        return {
            reportUrl: null,
            lastRefreshed: null,
            privacyURL: null,
            diclaimerURL: null,
            foiaURL: null,
            vulnerabilityDisclosure: null,
            oerURL: null,
            nihURL: null,
            hhsURL: null,
            usaGovURL: null,
            grantsURL: null,
            dataAccessPolicyURL: null
        };
    },
    props: {
        exporterPage: { type: Boolean }
    },
    computed: {
        ...mapGetters([
            'externalURLs'
        ]),
    },
    async created () {
        var requestedURLs = ['reporter_privacy_statement_url',
            'reporter_disclaimer_url',
            'reporter_foia_url',
            'reporter_hhs_vulnerability_disclosure_url',
            'reporter_oer_url',
            'reporter_hhs_url',
            'reporter_usa_gov_url',
            'reporter_grants_gov_url',
            'report_data_access_policy_url'
        ];

        await this.$store.dispatch('getAppSettingsPropertyByNameAction', requestedURLs);

        if (this.externalURLs) {
            this.privacyURL = this.externalURLs['reporter_privacy_statement_url']
            this.diclaimerURL = this.externalURLs['reporter_disclaimer_url'];
            this.foiaURL = this.externalURLs['reporter_foia_url'];
            this.vulnerabilityDisclosure = this.externalURLs['reporter_hhs_vulnerability_disclosure_url'];
            this.oerURL = this.externalURLs['reporter_oer_url'];
            this.nihURL = this.externalURLs['reporter_nih_url'];
            this.hhsURL = this.externalURLs['reporter_hhs_url'];
            this.usaGovURL = this.externalURLs['reporter_usa_gov_url'];
            this.grantsURL = this.externalURLs['reporter_grants_gov_url'];
            this.dataAccessPolicyURL = this.externalURLs['report_data_access_policy_url'];
            this.reportUrl = this.externalURLs['report_base_url'];
        }
		
		// Get created date of project elastic search index 
		const response = await axios.get(`/services/Projects/IndexCreatedDate`);
		if (response.status !== 200) throw Error(response.message);		
		this.lastRefreshed = response.data;
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.footer {
    margin-top: 25px;
    padding: 30px 0;
    font-size: 13px;
    color: $grey;
    background-color: $grey-lighter;
    text-align: center;
    a {
        text-decoration: none;
    }
    ul {
        padding: 0;
        list-style: none;

        li {
            display: inline-block;

            a {
                padding: 0px 15px;
                color: $grey;
            }

            +li a {
                border-left: 1px solid #b2b2b2;
            }
        }
    }

    .footer-tagline {
        margin: 20px 0 0;
    }
}

.footer-version {
    text-align: left;
    margin-bottom: 3em;
    font-size: 14px;

    a {
        color: $grey;
        font-weight: 400;
    }
}

.version {
    font-weight: 500;
}

.data-last-update {
    font-size: 13px;
}
</style>
